export const PathRoutes = {
  MAIN: '',
  NEWS: 'news',
  SOLUTION_DETAIL: 'solution/:id',
  SOLUTION: 'solution',
  NEWS_DETAIL: 'news/:id',
} as const

export const NavbarLink = {
  NEWS: PathRoutes.NEWS,
  DOCS: 'https://developers.gamescale.io',
}
