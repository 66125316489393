export const personalizedRecommendation = {
  seo: {
    title: '게임스케일 | 개인화 추천 솔루션',
    description:
      '데이터 기반의 맞춤형 콘텐츠를 통해 유저의 플레이 경험을 강화하는 개인화 추천 솔루션',
    ogTitle: '개인화 추천 솔루션',
    ogDescription:
      '데이터 기반의 맞춤형 콘텐츠를 통해 유저의 플레이 경험을 강화하는 개인화 추천 솔루션',
  },
  banner: {
    title: '개인화 추천 솔루션',
    subtitle: '데이터 기반의 맞춤형 콘텐츠를 통해\n유저의 플레이 경험을 강화하는',
    description:
      '유저의 행동 패턴과 취향을 깊이 분석하여 상황에 알맞은 콘텐츠를 다양한 방법으로 제공합니다. 그 결과로 더욱 풍부한 유저 경험을 선사할 수 있으며, 이는 곧 유저의 플레이 시간 증가와 이탈 방지로 이어집니다.',
  },
  analysis: {
    name: 'User Analysis',
    title1: '맞춤형 콘텐츠의 근간이 되는 유저 데이터 분석',
    title2: '',
    description:
      '콘텐츠의 대상 유저와 노출 방법을 결정할 수 있도록 방대한 양의 유저 데이터를 정교하게 분석합니다. 직관적인 시스템을 통해 필요한 데이터를 쉽게 검색하거나 조건에 맞게 추출할 수 있습니다.',
    features: {
      userProfile: '유저프로파일',
      dataExtractionTool: '데이터 추출 도구',
      dataExtractionToolLink: 'https://developers.gamescale.io/ko/services/16',
      userProfileLink: 'https://developers.gamescale.io/ko/services/15',
    },
  },
  personalizedContents: {
    name: 'Personalized Contents',
    title1: '유저의 필요와 흥미에 맞춰 제공되는',
    title2: ' 게임',
    title3: ' 안팎의',
    title4: ' 각종 정보 콘텐츠',
    description:
      '게임 플레이 중 허들이 발생했을 때 적절한 가이드를 노출하고, 유저의 구매 경험 및 성향에 따라 필요한 상품을 최적의 시점에 추천합니다. 또한 유저가 흥미를 갖는 요소와 플레이 패턴 등을 활용하여 개인화 뉴스 및 리포트와 게임 관련 영상 콘텐츠를 제공합니다.',
    features: {
      guideRecommendation: '가이드 추천',
      productRecommendation: '상품 추천',
      customPortal: '개인화 포털',
      guideRecommendationLink: 'https://developers.gamescale.io/ko/services/43',
      productRecommendationLink: 'https://developers.gamescale.io/ko/services/40',
      customPortalLink: 'https://developers.gamescale.io/ko/services/42',
    },
  },
  social: {
    name: 'Social Recommendations',
    title1: '유저의 성향과 플레이 상황을 고려한',
    title2: ' 매칭 및 소셜 추천 시스템',
    title3: '',
    description:
      '유저의 정보를 분석하여 최적의 경쟁 상대를 탐색하고 매칭합니다. 더불어 유저의 성향에 가장 이상적인 길드 및 길드원, 파티원, 친구를 비롯해 플레이 상황에 맞는 채팅 그룹을 추천하여 유저 간의 상호작용 및 정보 공유를 촉진합니다.',
    features: {
      matchMob: '매치몹',
      socialRecommendation: '소셜 추천',
      matchMobLink: 'https://developers.gamescale.io/ko/services/39',
      socialRecommendationLink: 'https://developers.gamescale.io/ko/services/41',
    },
  },
  marketing: {
    name: 'Marketing Optimization',
    title1: '정밀한 유저 타기팅으로 마케팅 효율 극대화',
    title2: '',
    description:
      '특정 조건에 해당하는 유저에게 맞춤형 배너를 원하는 빈도로 노출하고, 이탈 위험이 있는 유저를 식별하여 개인화 메시지를 발송합니다. 개인화된 마케팅은 리텐션 강화 및 유저의 복귀 유도에 매우 효과적입니다.',
    features: {
      nexonAdvertisement: '넥슨 광고',
      retentionKeeper: '리텐션 키퍼',
      nexonAdvertisementLink: 'https://developers.gamescale.io/ko/services/54',
      retentionKeeperLink: 'https://developers.gamescale.io/ko/services/53',
    },
  },
}

const riskManagement = {
  seo: {
    title: '게임스케일 | 리스크 관리 솔루션',
    description: '게임의 모든 여정을 고려하여 빈틈없이 보안하는 리스크 관리 솔루션',
    ogTitle: '리스크 관리 솔루션',
    ogDescription: '게임의 모든 여정을 고려하여 빈틈없이 보안하는 리스크 관리 솔루션',
  },
  banner: {
    title: '리스크 관리 솔루션',
    subtitle: '게임의 모든 여정을 고려하여\n빈틈없이 보안하는',
    description:
      '오랜 게임 운영 경험을 녹여 보호, 탐지, 제재까지 체계적으로 대응합니다. 게임이 안전할수록 개발 및 운영에 소요되는 리소스가 감소하며, 이는 곧 게임 퀄리티와 유저 만족도의 향상으로 이어집니다.',
  },
  authenticationSecurity: {
    name: 'Authentication Security',
    title1: '계정 로그인부터 철저하게 보호하는 ',
    title2: '엄격한 인증 ',
    title3: '시스템',
    description:
      '고급 인증 메커니즘과 추가 인증 설정으로 유저 및 게임 데이터에 대한 무단 액세스를 방어합니다. 더불어 계정의 보안 등급을 직관적으로 분류하고 패스워드의 취약점을 경고하여 안전을 견고히 합니다.',
    features: {
      accountProtectionSystem: '계정 보호',
      twoFactorAuthentication: '2단계 인증',
      accountTrustRating: '계정 신뢰도 측정',
      accountProtectionSystemLink: 'https://developers.gamescale.io/ko/services/36',
      twoFactorAuthenticationLink: 'https://developers.gamescale.io/ko/services/38',
      accountTrustRatingLink: 'https://developers.gamescale.io/ko/services/35',
    },
  },
  integrityObfuscation: {
    name: 'Integrity & Obfuscation',
    title1: '클라이언트를 안전하게 지키는 ',
    title2: '무결성 검증과 코드 ',
    title3: '난독화',
    description:
      '게임 클라이언트와 소스 코드의 무결성을 검증하고 게임 실행 환경을 확인하여 해킹 시도를 차단합니다. 또한 코드 난독화와 악성 파일 탐지 및 분석을 통해 역공학을 방지합니다.',
    features: {
      gameProtectionModule: '게임 보호',
      antiTamperingTechnology: '게임 위변조 방지',
      gameProtectionModuleLink: 'https://developers.gamescale.io/ko/services/34',
      antiTamperingTechnologyLink: 'https://developers.gamescale.io/ko/services/33',
    },
  },

  detection: {
    name: 'Detection',
    title1: '치팅에 의한 더 큰 피해를 방지하는 ',
    title2: '선제적 모니터링 및 탐지',
    title3: '',
    description:
      'AI로 방대한 데이터와 유저 패턴을 분석하여 게임 내에서 발생하는 부정행위를 빠르게 탐지합니다. 문제 탐지 시 신속하게 알림을 발송하며,  상황 분석 및 처치 진행을 위한 맞춤형 지표를 제공합니다.',
    features: {
      gameAnomalyDetection: '게임 이상 탐지',
      cheatDetection: '어뷰징 탐지',
      visionHackDetection: '비전핵 탐지',
      textDetection: '텍스트 탐지',
      imageDetection: '이미지 탐지',
      gameAnomalyDetectionLink: 'https://developers.gamescale.io/ko/services/32',
      cheatDetectionLink: 'https://developers.gamescale.io/ko/services/23',
      visionHackDetectionLink: 'https://developers.gamescale.io/ko/services/25',
      textDetectionLink: 'https://developers.gamescale.io/ko/services/28',
      imageDetectionLink: 'https://developers.gamescale.io/ko/services/27',
    },
  },
  safeSecureEnvironment: {
    name: 'Safe & Secure Environment',
    title1: '안정적인 서비스를 위한 결제 ',
    title2: '보안 강화 및 공격 ',
    title3: '트래픽 차단',
    description:
      '결제 과정에서의 이상 행위와 정상처럼 위장된 공격 트래픽을 분석 정보 기반의 정교한 차단 정책으로 섬세하게 탐지하고 대응합니다. 게임 내 거래의 안전을 보장하고 네트워크 위협으로부터 보호함으로써 게임을 보다 안정적으로 서비스할 수 있습니다.',
    features: {
      paymentAnomalyDetection: '이상 결제 탐지',
      networkBlockingSystem: '네트워크 차단',
      paymentAnomalyDetectionLink: 'https://developers.gamescale.io/ko/services/26',
      networkBlockingSystemLink: 'https://developers.gamescale.io/ko/services/31',
    },
  },
  restrictionManagement: {
    name: 'Restriction Management',
    title1: '상황별로 적절하게 대응할 수 있는 다양한 제재 기능',
    title2: '',
    description:
      '검출된 부정행위에 효율적으로 대응할 수 있도록 실시간 자동 제재와 수동 제재를 모두 제공합니다. 또한 게임에 알맞은 행동 범위 제한을 설정하여 의심 계정의 플레이를 제어할 수 있습니다.',
    features: {
      executionRestrictionSystem: '게임 실행 제한',
      realtimeBehavioralRestrictionSystem: '실시간 행동 제한',
      realtimeWeightControlSystem: '실시간 가중치 제어',
      executionRestrictionSystemLink: 'https://developers.gamescale.io/ko/services/24',
      realtimeBehavioralRestrictionSystemLink: 'https://developers.gamescale.io/ko/services/29',
      realtimeWeightControlSystemLink: 'https://developers.gamescale.io/ko/services/30',
    },
  },
}

const operation = {
  seo: {
    title: '게임스케일 | 운영 솔루션',
    description:
      '라이브 게임의 보다 안정적인 관리를 위해 다양한 도구와 시스템을 지원하는 운영 솔루션',
    ogTitle: '운영 솔루션',
    ogDescription:
      '라이브 게임의 보다 안정적인 관리를 위해 다양한 도구와 시스템을 지원하는 운영 솔루션',
  },
  banner: {
    title: '운영 솔루션',
    subtitle: '라이브 게임의 보다 안정적인 관리를 위해\n다양한 도구와 시스템을 지원하는',
    description:
      '유저의 불편 해소 및 원활한 게임 운영에 필요한 리소스를 절감하고 업무 효율을 극대화합니다. 이는 운영 품질을 향상시키고, 결과적으로 유저의 만족도를 높이는 효과를 가져옵니다.',
  },
  operationTools: {
    name: 'Operation Tools',
    title1: '효율적인 유저 대응을 뒷받침하는 운영 지원 도구',
    title2: '',
    description:
      '유저의 문의 접수 및 처리에 필요한 기능을 체계적으로 조합한 도구를 통해 고객 지원 서비스의 신속성과 효율성을 극대화하고, 유저의 문제를 보다 전문적으로 해결합니다.',
    features: {
      gameOperationTool: '게임 운영 도구',
      customerCenter: '고객 센터',
      autoCallingSystem: '자동 발신 ARS',
      dataExtractionTool: '데이터 추출 도구',
      notification: '알림',
      discordBot: '디스코드 봇',
      discordBotLink: 'https://developers.gamescale.io/ko/services/59',
      dataExtractionToolLink: 'https://developers.gamescale.io/ko/services/16',
      gameOperationToolLink: 'https://developers.gamescale.io/ko/services/46',
      customerCenterLink: 'https://developers.gamescale.io/ko/services/45',
      autoCallingSystemLink: 'https://developers.gamescale.io/ko/services/44',
      notificationLink: 'https://developers.gamescale.io/ko/services/50',
    },
  },
  restriction: {
    name: 'Restriction',
    title1: '실시간 탐지 시스템을 활용한 운영 정책 위반 행위 ',
    title2: '제한',
    description:
      '욕설, 매크로, 불법 작업장과 같은 게임 내 질서를 해치는 행위를 실시간으로 탐지하는 모니터링 시스템과 이와 연계된 제재 기능을 통해 관련 업무에 소요되는 리소스를 크게 절감할 수 있습니다.',
    features: {
      cheatDetection: '어뷰징 탐지',
      gameAnomalyDetection: '게임 이상 탐지',
      textDetection: '텍스트 탐지',
      imageDetection: '이미지 탐지',
      executionRestrictionSystem: '게임 실행 제한',
      gameAnomalyDetectionLink: 'https://developers.gamescale.io/ko/services/32',
      cheatDetectionLink: 'https://developers.gamescale.io/ko/services/23',
      textDetectionLink: 'https://developers.gamescale.io/ko/services/28',
      imageDetectionLink: 'https://developers.gamescale.io/ko/services/27',
      executionRestrictionSystemLink: 'https://developers.gamescale.io/ko/services/24',
    },
  },
  retention: {
    name: 'Retention',
    title1: '보다 간편한 이벤트 운영을 위한 관리 및 제작 도구',
    title2: '',
    description:
      '효과적으로 유저를 확보할 수 있는 수단인 이벤트를 수시로 원활하게 진행할 수 있도록 쿠폰 발행 및 관리와 이벤트 관련 이미지 제작 기능을 지원하는 도구를 제공합니다.',
    features: {
      coupons: '쿠폰',
      adCreator: 'AD Creator',
      couponsLink: 'https://developers.gamescale.io/ko/services/52',
      adCreatorLink: 'https://developers.gamescale.io/ko/services/47',
    },
  },
  compliance: {
    name: 'Compliance',
    title1: '법률을 준수할 수 있도록 설계된 상세한 관리 시스',
    title2: '템',
    description:
      '개인정보 보호법, 청소년 보호법, 확률형 아이템 규제 등 게임 운영 시 고려해야 하는 여러 법적 요구사항을 빈틈없이 충족할 수 있는 관리 시스템을 제공합니다.',
    features: {
      personalInformationCollectionSystem: '개인정보 수집',
      personalInformationManagementSystem: '개인정보 관리',
      protectionOfMinors: '청소년 보호',
      disclosureOfLootBoxOdds: '확률형 아이템 공시',
      personalInformationCollectionSystemLink: 'https://developers.gamescale.io/ko/services/37',
      personalInformationManagementSystemLink: 'https://developers.gamescale.io/ko/services/55',
      protectionOfMinorsLink: 'https://developers.gamescale.io/ko/services/62',
      disclosureOfLootBoxOddsLink: 'https://developers.gamescale.io/ko/services/61',
    },
  },
}

const creator = {
  seo: {
    title: '게임스케일 | 크리에이터 솔루션',
    description: '게임과 크리에이터의 상생을 시스템으로 구축한 크리에이터 솔루션',
    ogTitle: '크리에이터 솔루션',
    ogDescription: '게임과 크리에이터의 상생을 시스템으로 구축한 크리에이터 솔루션',
  },
  banner: {
    title: '크리에이터 솔루션',
    subtitle: '게임과 크리에이터의 상생을\n시스템으로 구축한',
    description:
      '실제 수익으로 전환할 수 있는 리워드를 연계한 프로그램으로 크리에이터 모집과 양질의 게임 콘텐츠 제작을 독려합니다. 유저와 크리에이터에게는 즐거움과 이익을 제공하고, 이를 통해 게임의 인지도 및 매출이 향상되는 선순환을 형성합니다.',
  },
  sponsorship: {
    name: 'Sponsorship',
    title1: '크리에이터와 유저가 ',
    title2: '후원을 통해 함께 성장하는 플랫폼',
    description:
      '게임과 파트너십을 맺은 크리에이터가 전용 미션을 수행하면 후원 유저들에게 보상이 지급되고, 후원 유저가 게임 내에서 상품 구매 시 지불 금액의 일부가 크리에이터에게 포인트로 적립됩니다. 크리에이터는 적립된 포인트를 현금으로 전환할 수 있습니다.',
    features: {
      creators: '크리에이터즈',
      withdrawal: '출금',
      creatorsLink: 'https://developers.gamescale.io/ko/services/49',
      withdrawalLink: 'https://developers.gamescale.io/ko/services/10',
    },
  },
  insights: {
    name: 'Insights',
    title1: '크리에이터 활용에 대한 분석으로 ',
    title2: '마케팅 인사이트 제공',
    description:
      '영상 콘텐츠의 트렌드를 모니터링하고 점유율, 조회수 등의 상세 지표를 분석합니다. 이를 통해 크리에이터 마케팅 전략을 보다 효과적으로 수립할 수 있습니다.',
    features: {
      trendi: 'TRENDi',
      trendiLink: 'https://developers.gamescale.io/ko/services/21',
    },
  },
}

const matchmaking = {
  seo: {
    title: '게임스케일 | 매치메이킹 솔루션',
    description: '최적의 경기 매칭을 통해 최고의 플레이 경험을 선사하는 매치메이킹 솔루션',
    ogTitle: '매치메이킹 솔루션',
    ogDescription: '최적의 경기 매칭을 통해 최고의 플레이 경험을 선사하는 매치메이킹 솔루션',
  },
  banner: {
    title: '매치메이킹 솔루션',
    subtitle: '최적의 경기 매칭을 통해\n최고의 플레이 경험을 선사하는',
    description:
      '다양한 라이브 게임 노하우와 방대한 유저 데이터를 통해 고도화된 매칭 로직으로 유저에게 최적의 대전 상대와 플레이 환경을 추천하여 더욱 재미있고 만족스러운 게임 경험을 제공합니다.',
  },
  matchmaking: {
    name: 'Matchmaking',
    title1: '매칭이 필요한 모든 순간에 ',
    title2: '손쉽게 연동하는 매치메이커',
    description:
      '게임과 유저의 특성을 고려한 고도화된 매칭 로직으로 최적의 상대와 플레이 환경을 추천하며, 실시간 매칭 설정 변경 기능과 모니터링 도구를 제공합니다. 경쟁을 위한 PVP 팀 대전부터 파티모집을 위한 PVE까지 다양한 게임 모드에서 활용할 수 있으며, API를 이용한 간편한 제품 연동을 지원합니다.',
    features: {
      matchMob: '매치몹',
      matchMobLink: 'https://developers.gamescale.io/ko/services/39',
    },
  },
  optimization: {
    name: 'Optimization',
    title1: '유저의 실력과 성향을 고려한 ',
    title2: '매칭으로 더욱 즐거워지는 게임',
    title3: '',
    description:
      '매칭 요청 시점에 전달된 유저 데이터에 기반해 고품질의 매칭 시스템을 제공합니다. 치팅 기록, 비매너 지수, 그리고 회원 및 결제 정보 등 다양한 유저 정보를 활용하여 게임에서 필요한 맞춤 기능을 제작할 수 있으며, 여러 기능을 조합하여 매칭 시스템을 설계할 수 있습니다.',
    features: {
      userProfile: '유저프로파일',
      cheatDetection: '어뷰징 탐지',
      userProfileLink: 'https://developers.gamescale.io/ko/services/15',
      cheatDetectionLink: 'https://developers.gamescale.io/ko/services/23',
    },
  },
}

const gamescaleAI = {
  seo: {
    title: '게임스케일 | GameScale AI 솔루션',
    description:
      '고도화된 인공지능 기술과 함께 게임의 가능성을 무한대로 넓히는 GameScale AI 솔루션',
    ogTitle: 'GameScale AI 솔루션',
    ogDescription:
      '고도화된 인공지능 기술과 함께 게임의 가능성을 무한대로 넓히는 GameScale AI 솔루션',
  },
  banner: {
    title: 'GameScale AI 솔루션',
    subtitle: '고도화된 인공지능 기술과 함께\n게임의 가능성을 무한대로 넓히는',
    description:
      'AI는 일관된 판단하에 다양한 작업을 쉬지 않고 실시간으로 처리함으로써 업무 효율을 높이는 것은 물론, 수동으로 대응하기 어려웠던 과제를 해결합니다. 또한 실현 가능한 기술의 범위를 확장하여 게임의 새로운 미래를 만듭니다.',
  },
  security: {
    name: 'Security',
    title1: 'AI를 통한 실시간 문제 상황 탐지로 공정하고 안전한 게임 환경 제공',
    title2: '',
    description:
      '게임 규칙에 위배되는 행위를 AI로 탐지하고 유저의 게임 경험을 저해시킬 수 있는 요소를 실시간으로 식별해 적절한 조치를 내릴 수 있도록 지원합니다.',
    features: {
      gameAnomalyDetection: '게임 이상 탐지',
      cheatDetection: '어뷰징 탐지',
      visionHackDetection: '비전핵 탐지',
      gameAnomalyDetectionLink: 'https://developers.gamescale.io/ko/services/32',
      cheatDetectionLink: 'https://developers.gamescale.io/ko/services/23',
      visionHackDetectionLink: 'https://developers.gamescale.io/ko/services/25',
    },
  },
  moderation: {
    name: 'Moderation',
    title1: 'AI 분석과 챗봇으로 완성하는 더욱 깨끗하고 건전한 커뮤니티',
    title2: '',
    description:
      '인게임 채팅과 각종 게임 커뮤니티에서 욕설, 음란, 갈등 조장 표현 및 도배와 같은 행위를 실시간으로 탐지하고 삭제하여 더욱 쾌적한 커뮤니티 환경을 만듭니다.',
    features: {
      discordBot: '디스코드 봇',
      textDetection: '텍스트 탐지',
      imageDetection: '이미지 탐지',
      discordBotLink: 'https://developers.gamescale.io/ko/services/59',
      textDetectionLink: 'https://developers.gamescale.io/ko/services/28',
      imageDetectionLink: 'https://developers.gamescale.io/ko/services/27',
    },
  },
  enhancement: {
    name: 'Enhancement',
    title1: '맞춤형 경기부터 상품, 콘텐츠까지 AI가 선사하는 더 풍부한 게임 경험',
    title2: '',
    title3: '',
    description:
      'AI가 분석한 유저의 행동과 선호도를 기반으로 최적의 매치, 상품, 소셜을 추천함으로써 유저의 즐거움을 높이고 참여도를 극대화합니다. 또한, 유저의 플레이 정보에 기반한 맞춤형 콘텐츠를 제공하여 게임에 대한 애착을 강화하고 이탈을 방지할 수 있습니다.',
    features: {
      matchMob: '매치몹',
      productRecommendation: '상품 추천',
      socialRecommendation: '소셜 추천',
      retentionKeeper: '리텐션 키퍼',
      productRecommendationLink: 'https://developers.gamescale.io/ko/services/40',
      matchMobLink: 'https://developers.gamescale.io/ko/services/39',
      socialRecommendationLink: 'https://developers.gamescale.io/ko/services/41',
      retentionKeeperLink: 'https://developers.gamescale.io/ko/services/53',
    },
  },
  analysis: {
    name: 'Analysis',
    title: 'AI 기반 분석 지원 도구로 쉽고 간편하게 확인하는 게임 현황',
    description:
      '게임별 콘텐츠 트렌드를 쉽게 확인할 수 있도록 AI 기반의 영상 콘텐츠 및 감성 분석 기능이 탑재된 콘텐츠 모니터링 대시보드를 제공합니다. AI 챗봇을 통한 직관적인 데이터 추출이 가능하고 게임 이탈 원인을 AI로 분석할 수 있어 게임 분석 및 운영의 효율이 향상됩니다.',
    features: {
      trendi: 'TRENDi',
      dataExtractionTool: '데이터 추출 도구',
      userprofile: '유저프로파일',
      dataExtractionToolLink: 'https://developers.gamescale.io/ko/services/16',
      trendiLink: 'https://developers.gamescale.io/ko/services/21',
      userProfileLink: 'https://developers.gamescale.io/ko/services/15',
    },
  },
}

const marketing = {
  seo: {
    title: '게임스케일 | 마케팅 솔루션',
    description:
      '타깃별 맞춤형 전략으로 유저를 모객하고, 유입된 유저의 이탈을 방지하는 마케팅 솔루션',
    ogTitle: '마케팅 솔루션',
    ogDescription:
      '타깃별 맞춤형 전략으로 유저를 모객하고, 유입된 유저의 이탈을 방지하는 마케팅 솔루션',
  },
  banner: {
    title: '마케팅 솔루션',
    subtitle: '타깃별 맞춤형 전략으로 유저를 모객하고,\n유입된 유저의 이탈을 방지하는',
    description:
      '데이터와 AI 기반의 고도화된 기술로 타깃을 선정하고, 광고 운영, 이벤트 진행, 시장 분석까지 마케팅의 전반을 지원함으로써 효과적인 신규 유저 확보 및 리텐션 유지를 도모합니다.',
  },
  targetAnalysis: {
    name: 'Target Analysis',
    title1: '보다 효율적인 광고 운영을 위한 데이터 기반의 타깃 선정',
    title2: '',
    description:
      '유저 단위로 집계한 대규모 인게임 데이터를 분석하여 마케팅 대상을 정밀하게 선별합니다. 간단한 클릭만으로 원하는 조건의 맞춤형 타깃 오디언스를 쉽게 추출할 수 있습니다.',
    features: {
      userProfile: '유저프로파일',
      dataExtractionTool: '데이터 추출 도구',
      dataExtractionToolLink: 'https://developers.gamescale.io/ko/services/16',
      userProfileLink: 'https://developers.gamescale.io/ko/services/15',
    },
  },
  adCreation: {
    name: 'Ad Creation',
    title1: '빠르고 간편한 맞춤형 배너 제작',
    title2: '',
    description:
      '비전문가도 높은 품질의 개인화된 배너를 손쉽게 제작할 수 있도록 다양한 콘셉트의 디자인 템플릿과 에셋을 제공합니다. 각 게임별 리소스를 활용하여 자동으로 배너를 생성할 수도 있습니다.',
    features: {
      adCreator: 'AD Creator',
      adCreatorLink: 'https://developers.gamescale.io/ko/services/47',
    },
  },
  marketingMedia: {
    name: 'Marketing Media',
    title: '적재적소에 활용할 수 있는 게임 내외부의 마케팅 채널',
    description:
      '홈페이지/런처/앱 등의 내부 지면뿐만 아니라 PC방, 알림, MMS, LMS, E-Mail 등의 외부 지면까지, 각 유저에게 메시지를 전달할 수 있는 다양한 마케팅 채널을 제공합니다.',
    features: {
      nexonAdvertisement: '넥슨 광고',
      customPortal: '개인화 포털',
      pcCafeAffiliation: 'PC방 연계',
      notifications: '알림',
      customPortalLink: 'https://developers.gamescale.io/ko/services/42',
      nexonAdvertisementLink: 'https://developers.gamescale.io/ko/services/54',
      notificationsLink: 'https://developers.gamescale.io/ko/services/50',
      pcCafeAffiliationLink: 'https://developers.gamescale.io/ko/services/48',
    },
  },
  retention: {
    name: 'Retention',
    title: '유저 상황에 딱 맞는 콘텐츠로 지속적인 흥미 유지',
    description:
      '이탈 위험이 높은 유저 그룹을 선정하여 각 그룹별 플레이 데이터를 기반으로 개인화 메시지와 가이드 등의 다양한 맞춤형 콘텐츠를 제공합니다. 유저의 플레이 맥락에 꼭 들어맞는 콘텐츠로 게임에 대한 애착을 강화하고 이탈을 방지할 수 있습니다.',
    features: {
      retentionKeeper: '리텐션키퍼',
      guideRecommendation: '가이드 추천',
      guideRecommendationLink: 'https://developers.gamescale.io/ko/services/43',
      retentionKeeperLink: 'https://developers.gamescale.io/ko/services/53',
    },
  },
  creatorMarketing: {
    name: 'Creator Marketing',
    title: '트렌디하고 매력적인 모객 모델, 크리에이터 콘텐츠',
    description:
      '보상과 연계한 크리에이터 후원 시스템을 통해 양질의 게임 콘텐츠를 제작하는 크리에이터를 지원하고, 콘텐츠 생산에 대한 동기를 지속적으로 부여합니다. 크리에이터가 제작한 콘텐츠는 이를 소비하는 신규 유저들을 게임으로 자연스럽게 유입시키고, 기존 유저들의 리텐션을 높이는 역할을 합니다.',
    features: {
      creators: '크리에이터즈',
      creatorsLink: 'https://developers.gamescale.io/ko/services/49',
    },
  },
  promotionManagement: {
    name: 'Promotion Management',
    title1: '안전하고 편리한 이벤트 진행 및 관리',
    title2: '',
    description:
      '국내외 유저의 개인정보를 법령에 따라 안전하게 수집하여 경품을 지급할 수 있습니다. 또한 강력하고 유연한 쿠폰 관리 시스템을 통해 이벤트 쿠폰을 간편하게 발행할 수 있습니다.',
    features: {
      personalInformationCollectionSystem: '개인정보 수집',
      coupons: '쿠폰 ',
      couponsLink: 'https://developers.gamescale.io/ko/services/52',
      personalInformationCollectionSystemLink: 'https://developers.gamescale.io/ko/services/37',
    },
  },
  marketInsights: {
    name: 'Market Insights',
    title: '국내외 게임 시장 모니터링 시스템을 통해 시장의 트렌드 및 인사이트 제공',
    description:
      '게임별 콘텐츠 트렌드를 쉽게 확인할 수 있도록 커뮤니티 내 영상 콘텐츠 현황을 모니터링하여 시각화한 대시보드를 제공합니다. 또한 전국 PC방의 실시간 데이터 분석을 통해 국내 PC 게임 시장의 흐름을 파악하고, 다양한 UX 정보 수집을 통해 유저들의 니즈를 확인할 수 있습니다.',
    features: {
      trendi: 'TRENDi',
      pcCafeAffiliation: 'PC방 연계',
      uxResearchPlatform: 'UX 리서치 플랫폼',
      trendiLink: 'https://developers.gamescale.io/ko/services/21',
      pcCafeAffiliationLink: 'https://developers.gamescale.io/ko/services/48',
      uxResearchPlatformLink: 'https://developers.gamescale.io/ko/services/19',
    },
  },
}

const qa = {
  seo: {
    title: '게임스케일 | QA 솔루션',
    description: '효율성을 극대화한 각종 테스트로 게임 품질을 철저하게 관리하는 QA 솔루션',
    ogTitle: 'QA 솔루션',
    ogDescription: '효율성을 극대화한 각종 테스트로 게임 품질을 철저하게 관리하는 QA 솔루션',
  },
  banner: {
    title: 'QA 솔루션',
    subtitle: '효율성을 극대화한 각종 테스트로\n게임 품질을 철저하게 관리하는',
    description:
      'QA 전문가가 진행하는 다양한 방면의 테스트를 더욱 효율적으로 수행하기 위한 각종 도구를 제공하여 최적의 게임 환경 조성 및 유저 경험 개선에 기여합니다.',
  },
  advancedTools: {
    name: 'Advanced Tools',
    title: 'QA 전문가의 테스트를 조력하는 다양한 도구와 서비스',
    description:
      '테스트 케이스 관리 및 테스트 자동 수행 도구를 통해 QA 전문가가 업무를 효율적으로 수행할 수 있도록 돕습니다. 또한 게임의 품질을 빈틈없이 체크할 수 있도록 앱 성능 측정, 테스트 화면 상시 녹화, 클라이언트 성능 정보 집계 등의 기술을 제공합니다.',
    features: {
      qaAutomation: 'QA 자동화 및 효율화 ',
      qaSupportTools: 'QA 지원 도구',
      clientPerformanceMeasurement: '클라이언트 성능 측정',
      crashReport: '크래시 리포트',
      qaAutomationLink: 'https://developers.gamescale.io/ko/services/64',
      qaSupportToolsLink: 'https://developers.gamescale.io/ko/services/63',
      clientPerformanceMeasurementLink: 'https://developers.gamescale.io/ko/services/65',
      crashReportLink: 'https://developers.gamescale.io/ko/services/66',
    },
  },
  qualityVerification: {
    name: 'Quality Verification',
    title1: '게임의 원활한 작동을 확인하기 위한 각종 테스트 지원',
    title2: '',
    description:
      'PC와 모바일 기기의 성능 테스트, 다양한 하드웨어 조합과 소프트웨어에서의 호환성 검증, 스트레스 및 네트워크 부하 테스트 등 다양한 테스트를 수행하고, 테스트 결과 리포트를 제공합니다.',
    featureDetails: {
      hardwareCompatibilityVerification: {
        title: '하드웨어 호환성 검증',
        hardwareCompatibilityTest: {
          title: '하드웨어 호환성 테스트',
          content1: 'CPU, GPU, Memory 등 부품 간의 호환성 검증',
          content2: '기기 스펙 등급에 따른 클라이언트 실행 적절성 여부 확인',
        },
        performanceTest: {
          title: '퍼포먼스 테스트',
          content: '실행 시 fps 측정 및 시스템 리소스 점유율 확인',
        },
      },
      softwareCompatibilityVerification: {
        title: '소프트웨어 호환성 검증',
        creationOfArtificialEntryBarriers: {
          title: '인위적 진입 장벽 생성',
          content: '인위적인 진입 장벽을 만들어 다양한 환경에서의 정상 작동 여부 검증',
        },
        verificationOfExecutableOSVersions: {
          title: '실행 가능한 OS 버전 검증',
          content: '다양한 운영 체제의 버전에서 게임이 잘 작동하는지 여부 검증',
        },
        antivirusCompatibilityVerification: {
          title: '백신 호환성 검증',
          content: '게임과 백신과의 호환성 검사 및 충돌 여부 파악',
        },
        worldwideAntiVirusTest: {
          title: '전세계 Anti-Virus 검사',
          content: '각종 Anti-Virus 소프트웨어에서의 게임 클라이언트 및 설치 파일 감지 여부 검증',
        },
      },
    },
  },
}

const analytics = {
  seo: {
    title: '게임스케일 | 분석 솔루션',
    description:
      '데이터 기반의 현황 분석을 통해 게임의 성과 향상을 위한 전략을 제시하는 분석 솔루션',
    ogTitle: '분석 솔루션',
    ogDescription:
      '데이터 기반의 현황 분석을 통해 게임의 성과 향상을 위한 전략을 제시하는 분석 솔루션',
  },
  banner: {
    title: '분석 솔루션',
    subtitle: '데이터 기반의 현황 분석을 통해\n게임의 성과 향상을 위한 전략을 제시하는',
    description:
      'MMORPG, 액션, FPS, 레이싱, 스포츠 등 모든 장르에서 데이터 추출부터 유저 행동 분석, 현상 모니터링까지 체계적으로 분석을 진행합니다. 분석 결과를 통해 현황을 파악하고, 유저 이탈 방지 및 매출 증대를 위한 전략을 도출할 수 있습니다.',
  },
  data: {
    name: 'Data',
    title: '다양한 기반 데이터로 효과적인 게임 경험 파악',
    description:
      '게임 플레이 정보부터 유저의 실제 피드백까지 다양한 데이터를 통해 게임 경험을 깊이 분석할 수 있도록 데이터 수집 및 추출에 특화된 도구를 제공합니다. 데이터는 분석 및 열람하기 쉬운 형태로 제공되어 유저의 요구사항을 파악하는데 기여합니다.',
    features: {
      dataExtractionTool: '데이터 추출 도구',
      userProfile: '유저프로파일',
      monolake: 'Monolake',
      uxResearchPlatform: 'UX 리서치 플랫폼',
      monolakeLink: 'https://developers.gamescale.io/ko/services/17',
      dataExtractionToolLink: 'https://developers.gamescale.io/ko/services/16',
      userProfileLink: 'https://developers.gamescale.io/ko/services/15',
      uxResearchPlatformLink: 'https://developers.gamescale.io/ko/services/19',
    },
  },
  analysis: {
    name: 'Analysis',
    title: '유저 테스트와 행동 로그 분석을 통해 게임의 성장을 위한 전략 도출',
    description:
      '유저가 겪는 어려움을 유저테스트를 통해 직접 수집하고, 로그에 기반해 다양한 가설을 검증함으로써 잠재 유저 및 목표 시장의 핵심 성공 요소를 분석합니다. 이를 바탕으로 유저의 기대를 충족시키는 방향을 정립하고, 게임 경험을 향상하기 위한 전략을 도출합니다.',

    features: {
      dataAnalysis: '데이터 분석',
      uxAnalysis: 'UX 분석',
      uxAnalysisLink: 'https://developers.gamescale.io/ko/services/20',
      dataAnalysisLink: 'https://developers.gamescale.io/ko/services/18',
    },
  },
  report: {
    name: 'Report',
    title: '각종 게임 지표를 집약한 리포트로 성장 기회 식별 및 성공 측정',
    description:
      '게임 개발 및 운영에 필수적인 핵심 지표들을 담고 있는 리포트를 통해 게임 성과를 정량적으로 분석하고 전략을 수립할 수 있습니다. KPI 달성 정도, 게임 콘텐츠 이용 현황, 이상 현상 등을 모니터링하여 게임의 현재 상태를 효과적으로 파악할 수 있습니다.',

    features: {
      nxLogReport: 'NXLog 리포트 ',
      nxLogReportLink: 'https://developers.gamescale.io/ko/services/14',
    },
  },
  trends: {
    name: 'Trends',
    title: '유튜브와 PC방 데이터로 분석한 최신 게임 및 시장 트렌드 현황',
    description:
      '게임 IP를 활용한 콘텐츠 풀을 모니터링하고 최신 트렌드를 시각화하여 대시보드로 제공합니다. 또한, 전국 PC방의 실시간 데이터로 게임 시장 흐름을 분석할 수 있습니다.',
    features: {
      trendi: 'TRENDi',
      pcCafeAffiliation: 'PC방 연계',
      trendiLink: 'https://developers.gamescale.io/ko/services/21',
      pcCafeAffiliationLink: 'https://developers.gamescale.io/ko/services/48',
    },
  },
}

const userCare = {
  seo: {
    title: '게임스케일 | 유저 케어 솔루션',
    description:
      '지속적인 소통과 세심한 운영으로 유저의 게임 참여와 신뢰도를 높이는 유저 케어 솔루션',
    ogTitle: '유저 케어 솔루션',
    ogDescription:
      '지속적인 소통과 세심한 운영으로 유저의 게임 참여와 신뢰도를 높이는 유저 케어 솔루션',
  },
  banner: {
    title: '유저 케어 솔루션',
    subtitle: '지속적인 소통과 세심한 운영으로\n유저의 게임 참여와 신뢰도를 높이는',
    description:
      '유저의 게임 경험을 보호하고 다양한 혜택을 체계적으로 제공합니다. 유저와의 원활한 소통 환경 조성과 유저 간 최적화된 매칭을 통해 게임의 재미와 신뢰도를 높여 리텐션 향상에 기여합니다.',
  },
  userFeedback: {
    name: 'User Feedback',
    title1: '유저의 의견에 귀 기울일 수 있는 ',
    title2: '다양한 채널',
    description:
      '커뮤니티, 고객센터, 유저 테스트 등 여러 채널을 통해 유저들이 자신의 의견을 다양한 방식으로 표현할 수 있는 공간을 제공합니다. 유저의 피드백을 적극적으로 반영한 세심하고 꼼꼼한 운영을 통해 유저의 게임 경험을 더욱 풍부하게 만들 수 있습니다.',
    features: {
      uxResearchPlatform: 'UX 리서치 플랫폼',
      officialCommunity: '공식 커뮤니티',
      customerCenter: '고객 센터',
      uxResearchPlatformLink: 'https://developers.gamescale.io/ko/services/19',
      customerCenterLink: 'https://developers.gamescale.io/ko/services/45',
      officialCommunityLink: 'https://developers.gamescale.io/ko/services/60',
    },
  },
  userTrust: {
    name: 'User Trust',
    title1: '투명한 운영과 철저한 보안으로 ',
    title2: '유저의 게임 신뢰도 향상',
    title3: '',
    description:
      '가챠 아이템의 투명한 확률 공개와 청소년 이용 시간 관리 등 법령을 준수하기 위한 기능들을 제공합니다. 또한, 강화된 보안 조치로 유저가 계정이 안전하게 보호받고 있음을 체감할 수 있기 때문에 게임에 대한 유저의 신뢰를 높일 수 있습니다.',
    features: {
      disclosureOfLootBoxOdds: '확률형 아이템 공시',
      protectionOfMinors: '청소년 보호',
      twoFactorAuthentication: '2단계 인증',
      accountTrustRating: '계정 신뢰도 측정',
      accountTrustRatingLink: 'https://developers.gamescale.io/ko/services/35',
      twoFactorAuthenticationLink: 'https://developers.gamescale.io/ko/services/38',
      protectionOfMinorsLink: 'https://developers.gamescale.io/ko/services/62',
      disclosureOfLootBoxOddsLink: 'https://developers.gamescale.io/ko/services/61',
    },
  },
  connection: {
    name: 'Connection',
    title1: '유저 간의 섬세한 연결로 ',
    title2: '함께하는 즐거움 강화',
    description:
      '유저 정보를 기반으로 최적의 경기 상대 및 동료를 매칭하여 게임의 재미를 극대화합니다. 또한, 메신저 시스템을 통해 유저 간의 소통을 용이하게 하여 교류를 강화합니다.',
    features: {
      matchMob: '매치몹',
      socialRecommendation: '소셜 추천',
      messenger: '메신저',
      matchMobLink: 'https://developers.gamescale.io/ko/services/39',
      socialRecommendationLink: 'https://developers.gamescale.io/ko/services/41',
      messengerLink: 'https://developers.gamescale.io/ko/services/58',
    },
  },
  participationBoosting: {
    name: 'Participation Boosting',
    title1: '유저의 참여도를 극대화하는 ',
    title2: '각종 혜택과 이벤트',
    description:
      '전국 가맹 PC방과 연계한 프리미엄 혜택과 유연한 쿠폰 시스템을 비롯해, 디스코드 봇 서비스와 크리에이터 창작 지원 프로그램을 활용하여 다양한 이벤트를 진행할 수 있습니다. 이를 통해 제공되는 혜택들은 유저의 참여도와 만족도를 향상하는 역할을 합니다.',
    features: {
      pcCafeAffiliation: 'PC방 연계',
      coupons: '쿠폰',
      discordBot: '디스코드 봇',
      creators: '크리에이터즈',
      discordBotLink: 'https://developers.gamescale.io/ko/services/59',
      creatorsLink: 'https://developers.gamescale.io/ko/services/49',
      couponsLink: 'https://developers.gamescale.io/ko/services/52',
      pcCafeAffiliationLink: 'https://developers.gamescale.io/ko/services/48',
    },
  },
}

const business = {
  seo: {
    title: '게임스케일 | 비즈니스 솔루션',
    description: '게임의 성공을 위한 파이프라인을 전략적으로 구축하는 비즈니스 솔루션',
    ogTitle: '비즈니스 솔루션',
    ogDescription: '게임의 성공을 위한 파이프라인을 전략적으로 구축하는 비즈니스 솔루션',
  },
  banner: {
    title: '비즈니스 솔루션',
    subtitle: '게임의 성공을 위한 파이프라인을\n전략적으로 구축하는',
    description:
      '게임 유저에 대한 심층적인 이해를 바탕으로 지속적인 플레이를 유도하고, 이로부터 경제적 가치를 창출합니다. 이를 통해 게임의 성공적인 출시와 지속 가능한 운영에 필요한 견고한 기반을 마련할 수 있습니다.',
  },
  insights: {
    name: 'Insights',
    title1: '인게임 데이터를 가공하여 ',
    title2: '비즈니스 인사이트로 만드는 분석 도구',
    description:
      '수많은 인게임 데이터 중에서 필요한 지표를 대시보드, 리포트 등의 형식으로 제공합니다. 이를 활용한 유저의 행동과 선호에 대한 세부적인 분석은 비즈니스 전략의 강력한 근거로 활용됩니다.',
    features: {
      nxLogReport: 'NXLog 리포트',
      userProfile: '유저프로파일',
      dataExtractionTool: '데이터 추출 도구',
      dataExtractionToolLink: 'https://developers.gamescale.io/ko/services/16',
      userProfileLink: 'https://developers.gamescale.io/ko/services/15',
      nxLogReportLink: 'https://developers.gamescale.io/ko/services/14',
    },
  },
  retentionStrategy: {
    name: 'Retention Strategy',
    title1: '비즈니스의 지속 가능한 성장을 위한 ',
    title2: '리텐션 강화 전략',
    description:
      '이탈 유저를 대상으로 한 집중적인 광고 집행부터 크리에이터 후원과 연계한 콘텐츠 마케팅까지 상황과 목적에 맞는 전략을 활용하여 유저를 효율적으로 유지할 수 있습니다.',
    features: {
      retentionKeeper: '리텐션키퍼',
      creators: '크리에이터즈',
      retentionKeeperLink: 'https://developers.gamescale.io/ko/services/53',
      creatorsLink: 'https://developers.gamescale.io/ko/services/49',
    },
  },
  commerce: {
    name: 'Commerce',
    title1: '구매 동기 부여부터 손쉬운 결제까지 ',
    title2: '끊김 없는 인게임 경제 시스템',
    description:
      '유저의 니즈에 맞게 게임 내 상점에서 판매 중인 아이템을 추천하여 구매 욕구를 자극합니다. 이와 더불어 편리한 결제 및 캐시 충전 시스템을 제공하여 실제 구매로 연결될 수 있도록 유도합니다.',
    features: {
      productRecommendation: '상품 추천',
      store: '상점',
      payments: '결제',
      productRecommendationLink: 'https://developers.gamescale.io/ko/services/40',
      storeLink: 'https://developers.gamescale.io/ko/services/12',
      paymentsLink: 'https://developers.gamescale.io/ko/services/13',
    },
  },
  pcCafe: {
    name: 'PC-Cafe',
    title1: 'PC방과의 상생이 만드는 ',
    title2: '신규 수익 모델',
    description:
      '업주에게 PC방에서만 누릴 수 있는 프리미엄 혜택 제공 권한을 판매하여 이용자의 방문률을 높이는 방식으로 모두가 만족하는 수익 모델을 구축합니다. 또한 PC방 인프라를 활용한 관리 및 마케팅 솔루션을 제공합니다.',
    features: {
      pcCafeAffiliation: 'PC방 연계',
      pcCafeAffiliationLink: 'https://developers.gamescale.io/ko/services/48',
    },
  },
}

const community = {
  seo: {
    title: '게임스케일 | 커뮤니티 솔루션',
    description: '유저와 유저, 유저와 게임 간의 활발한 소통을 만드는 커뮤니티 솔루션',
    ogTitle: '커뮤니티 솔루션',
    ogDescription: '유저와 유저, 유저와 게임 간의 활발한 소통을 만드는 커뮤니티 솔루션',
  },
  banner: {
    title: '커뮤니티 솔루션',
    subtitle: '유저와 유저, 유저와 게임 간의\n활발한 소통을 만드는',
    description:
      '게임 커뮤니티와 각종 소셜 기능을 통해 유저 간의 소통을 장려합니다. 건전하고 원활한 소통 환경을 조성함으로써 게임이 유저와 더 가깝게 상호작용하고, 유저의 의견에 귀 기울여 게임 개선과 발전을 위한 소중한 통찰을 얻을 수 있습니다.',
  },
  engagement: {
    name: 'Engagement',
    title1: '쉽고 간단하게 사용할 수 있는 ',
    title2: '커뮤니티 환경 조성 도구',
    description:
      '서비스 지역, 특화 기능 등 게임의 색깔에 맞춰 커스터마이징할 수 있는 공식 커뮤니티와 텍스트/음성/영상 등 다양한 형태의 채팅, 그리고 멀티 플랫폼 간의 메신저를 제공합니다.',
    features: {
      officialCommunity: '공식 커뮤니티',
      chat: '채팅',
      messenger: '메신저',
      messengerLink: 'https://developers.gamescale.io/ko/services/58',
      officialCommunityLink: 'https://developers.gamescale.io/ko/services/60',
      chatLink: 'https://developers.gamescale.io/ko/services/56',
    },
  },
  encouragement: {
    name: 'Encouragement',
    title1: '유저와 잘 맞는 소셜 추천으로 ',
    title2: '커뮤니케이션 채널 활성화',
    description:
      '유저의 플레이 경험과 데모그래픽 정보를 기반으로 가장 알맞은 길드 및 길드원, 파티원, 친구 등을 추천합니다. 또한 플레이 중 특정 조건이 충족된 유저에게 관련 채팅 그룹을 추천함으로써 여러 유저들과 함께 같은 경험을 소재로 소통할 수 있는 환경을 조성합니다.',
    features: {
      socialRecommendation: '소셜 추천',
      socialRecommendationLink: 'https://developers.gamescale.io/ko/services/41',
    },
  },
  management: {
    name: 'Management',
    title1: '신속하고 편리한 운영을 통해 ',
    title2: '최적화된 커뮤니티 경험 제공',
    description:
      '자동 메시지, 소식 알림, FAQ, 쿠폰 배포 등 게임 커뮤니티 관리에 필요한 다양한 기능을 가진 디스코드 봇을 이용할 수 있습니다. 또한 채팅에서 발생하는 텍스트, 사용자가 업로드하는 이미지 등의 유해 여부를 AI로 탐지하여 차단함으로써 건강한 소통 환경을 유지합니다.',
    features: {
      discordBot: '디스코드 봇',
      textDetection: '텍스트 탐지',
      imageDetection: '이미지 탐지',
      discordBotLink: 'https://developers.gamescale.io/ko/services/59',
      textDetectionLink: 'https://developers.gamescale.io/ko/services/28',
      imageDetectionLink: 'https://developers.gamescale.io/ko/services/27',
    },
  },
}

const developmentSupport = {
  seo: {
    title: '게임스케일 | 비즈니스 솔루션',
    description: '게임에 필요한 각종 기능을 보다 쉽게 구현할 수 있도록 돕는 개발 지원 솔루션',
    ogTitle: '개발 지원 솔루션',
    ogDescription: '게임에 필요한 각종 기능을 보다 쉽게 구현할 수 있도록 돕는 개발 지원 솔루션',
  },
  banner: {
    title: '개발 지원 솔루션',
    subtitle: '게임에 필요한 각종 기능을\n보다 쉽게 구현할 수 있도록 돕는',
    description:
      '인증, 빌링 등의 복잡한 필수 기능부터 활용도 높은 지원 기능까지, 게임 개발과 출시에 필요한 다양한 기능을 연동하여 사용할 수 있습니다. 직접 하나하나 구현해야 하는 개발의 부담을 줄이고 게임의 품질과 시장 경쟁력을 높이는 데에 집중할 수 있도록 지원합니다.',
  },
  authentication: {
    name: 'Authentication',
    title1: '포괄적이고 탄탄한 ',
    title2: '인증 및 계정 시스템',
    description:
      '회원 관리부터 로그인, 계정 유효성 검증까지 게임 및 서비스의 이용을 위한 계정 체계와 인증 과정을 면밀하게 지원합니다. 해외 출시 게임을 위한 글로벌 계정 시스템을 제공하며, 멀티 플랫폼에서의 크로스 프로그레션이 가능합니다.',
    features: {
      nexonLogin: '넥슨 로그인',
      nexonMembers: '넥슨 회원',
      integratedAccountLinking: '계정 통합 연결',
      memberChanneling: '회원 채널링',
      nexonLoginLink: 'https://developers.gamescale.io/ko/services/7',
      nexonMembersLink: 'https://developers.gamescale.io/ko/services/6',
      integratedAccountLinkingLink: 'https://developers.gamescale.io/ko/services/9',
      memberChannelingLink: 'https://developers.gamescale.io/ko/services/8',
    },
  },
  commerce: {
    name: 'Commerce',
    title1: '유연하고 안전한 ',
    title2: '결제 및 상점 시스템',
    description:
      '고도화된 검증 로직을 포함한 결제 시스템을 게임 플랫폼에 맞춰 간편하게 연동할 수 있습니다. 더불어 인게임, 웹, 런처 등 다양한 창구에서의 아이템 판매와 관리 기능을 지원합니다.',
    features: {
      payments: '결제',
      store: '상점',
      currency: '재화',
      withdrawal: '출금',
      withdrawalLink: 'https://developers.gamescale.io/ko/services/10',
      storeLink: 'https://developers.gamescale.io/ko/services/12',
      paymentsLink: 'https://developers.gamescale.io/ko/services/13',
      currencyLink: 'https://developers.gamescale.io/ko/services/11',
    },
  },
  clientAndServerUtilites: {
    name: 'Client & Server Utilities',
    title1: '간편하고 체계적인 ',
    title2: '게임 실행 및 서버 지원',
    description:
      '게임 서버 구축 및 클라이언트 연동을 쉽게 할 수 있도록 돕는 라이브러리를 제공합니다. 또한 게임의 빌드 관리, 다운로드, 설치, 업데이트, 실행을 위한 일련의 과정을 모두 간단히 관리할 수 있습니다.',
    features: {
      serverBuildingLibrary: '서버 구축 라이브러리',
      launcher: '런처',
      deployment: '배포',
      crashReport: '크래시 리포트',
      serverBuildingLibraryLink: 'https://developers.gamescale.io/ko/services/69',
      clientPerformanceMeasurement: '클라이언트 성능 측정',
      clientPerformanceMeasurementLink: 'https://developers.gamescale.io/ko/services/65',
      crashReportLink: 'https://developers.gamescale.io/ko/services/66',
      launcherLink: 'https://developers.gamescale.io/ko/services/4',
      deploymentLink: 'https://developers.gamescale.io/ko/services/3',
    },
  },
  developmentModule: {
    name: 'Development Module',
    title1: '인게임 콘텐츠 구현에 효율적인 ',
    title2: '개발 지원 모듈',
    description:
      '시스템 리소스에 영향을 주지 않고 클라이언트 내에서 웹페이지를 구현할 수 있습니다. 모듈 형태로 제공되기 때문에 게임의 특성에 맞춰 다양한 인게임 콘텐츠에서 활용하기에 효과적입니다.',
    features: {
      webViewSupport: '웹뷰 지원',
      webViewSupportLink: 'https://developers.gamescale.io/ko/services/68',
    },
  },
}

export const solutionDetail = {
  personalizedRecommendation,
  riskManagement,
  operation,
  creator,
  matchmaking,
  gamescaleAI,
  marketing,
  qa,
  analytics,
  userCare,
  business,
  community,
  developmentSupport,
}
