const personalizedRecommendation = {
  seo: {
    title: 'GameScale | Personalized Recommendation Solution',
    description:
      'Personalized Recommendation Solution that enhances user play experience through data-driven customized content',
    ogTitle: 'Personalized Recommendation Solution',
    ogDescription:
      'Personalized Recommendation Solution that enhances user play experience through data-driven customized content',
  },
  banner: {
    title: 'Personalized\nRecommendation Solution',
    subtitle: 'that enhances user play experience through data-driven customized content',
    description:
      'It deeply analyzes user behavior patterns and preferences to provide appropriate content in various ways. This leads to a richer user experience, increasing playtime and reducing user churn.',
  },
  analysis: {
    name: 'User Analysis',
    title1: 'User data analysis as the foundation of ',
    title2: 'customized content',
    description:
      'To determine the target audience and exposure methods for content, it precisely analyzes a vast amount of user data. The intuitive system enables easy searching of necessary data and extraction of data that meets specific criteria.',
    features: {
      userProfile: 'USER PROFILE',
      dataExtractionTool: 'Data Extraction',
      dataExtractionToolLink: 'https://developers.gamescale.io/en/services/16',
      userProfileLink: 'https://developers.gamescale.io/en/services/15',
    },
  },
  personalizedContents: {
    name: 'Personalized Contents',
    title1: 'In-game and external information content ',
    title2: 'tailored to user needs and interests',
    title3: '',
    title4: '',
    description:
      'It displays guides appropriate for the hurdles encountered during gameplay and recommends products at the optimal time based on user purchase experience and preferences. Additionally, it utilizes elements of user interest and gameplay patterns to provide personalized news, reports, and gaming video contents.',
    features: {
      guideRecommendation: 'Guide Recommendation',
      productRecommendation: 'Product Recommendation',
      customPortal: 'Custom Portal',
      guideRecommendationLink: 'https://developers.gamescale.io/en/services/43',
      productRecommendationLink: 'https://developers.gamescale.io/en/services/40',
      customPortalLink: 'https://developers.gamescale.io/en/services/42',
    },
  },
  social: {
    name: 'Social Recommendations',
    title1: 'Matching and social recommendation ',
    title2: 'tailored to ',
    title3: 'user preferences and gameplay context',
    description:
      'It analyzes user data to find and match the best competitors and recommends ideal guilds, guild members, party members, and friends, as well as chat groups based on user preferences and gameplay, enhancing interaction and information sharing among users.',
    features: {
      matchMob: 'MatchMob',
      socialRecommendation: 'Social Recommendation',
      matchMobLink: 'https://developers.gamescale.io/en/services/39',
      socialRecommendationLink: 'https://developers.gamescale.io/en/services/41',
    },
  },
  marketing: {
    name: 'Marketing Optimization',
    title1: 'Precision user targeting for maximizing ',
    title2: 'marketing efficiency',
    description:
      'It displays customized banners at desired frequencies to users who meet specific criteria, and identifies users at risk of churn to send personalized messages. Personalized marketing is highly effective in enhancing retention and encouraging user return.',
    features: {
      nexonAdvertisement: 'NEXON Adverisement',
      retentionKeeper: 'Retention Keeper',
      nexonAdvertisementLink: 'https://developers.gamescale.io/en/services/54',
      retentionKeeperLink: 'https://developers.gamescale.io/en/services/53',
    },
  },
}

const riskManagement = {
  seo: {
    title: 'GameScale | Risk Management Solution',
    description: 'Risk Management Solution that securely covers every journey of gameplay',
    ogTitle: 'Risk Management Solution',
    ogDescription: 'Risk Management Solution that securely covers every journey of gameplay',
  },
  banner: {
    title: 'Risk Management\nSolution',
    subtitle: 'that securely covers every journey of gameplay',
    description:
      'Leveraging extensive game operation experience,  it systematically performs protection, detection, and sanctions.  The safer the game, the fewer resources needed  for development and operations, leading to enhanced game quality and user satisfaction.',
  },
  authenticationSecurity: {
    name: 'Authentication Security',
    title1: 'Stringent authentication system ',
    title2: 'thoroughly protects account logins',
    title3: '',
    description:
      'An advanced authentication mechanism and additional security settings prevent unauthorized access to user and game data. Furthermore, it solidifies security by intuitively categorizing account safety levels and alerting to password vulnerabilities.',
    features: {
      accountProtectionSystem: 'Account Protection',
      twoFactorAuthentication: 'Two-Factor Authentication',
      accountTrustRating: 'Account Trust Rating',
      accountProtectionSystemLink: 'https://developers.gamescale.io/en/services/36',
      twoFactorAuthenticationLink: 'https://developers.gamescale.io/en/services/38',
      accountTrustRatingLink: 'https://developers.gamescale.io/en/services/35',
    },
  },
  integrityObfuscation: {
    name: 'Integrity & Obfuscation',
    title1: 'Integrity verification and code obfuscation ',
    title2: 'to ',
    title3: 'securely protect the client',
    description:
      'We verify the integrity of game clients and source codes to block hacking attempts, and prevent reverse engineering through code obfuscation and malware analysis.',
    features: {
      gameProtectionModule: 'Game Protection',
      antiTamperingTechnology: 'Anti-Tampering',
      gameProtectionModuleLink: 'https://developers.gamescale.io/en/services/34',
      antiTamperingTechnologyLink: 'https://developers.gamescale.io/en/services/33',
    },
  },
  detection: {
    name: 'Detection',
    title1: 'Proactive monitoring and detection ',
    title2: 'to prevent major damage ',
    title3: 'from cheat behaviors',
    description:
      'AI analyzes vast data and user patterns to quickly detect cheat behaviors within the game. Alerts are sent promptly upon detection, and customized metrics are provided for situation analysis and remediation.',
    features: {
      gameAnomalyDetection: 'Game Anomaly Detection',
      cheatDetection: 'Cheat Detection',
      visionHackDetection: 'Vision Hack Detection',
      textDetection: 'Text Detection',
      imageDetection: 'Image Detection',
      gameAnomalyDetectionLink: 'https://developers.gamescale.io/en/services/32',
      cheatDetectionLink: 'https://developers.gamescale.io/en/services/23',
      visionHackDetectionLink: 'https://developers.gamescale.io/en/services/25',
      textDetectionLink: 'https://developers.gamescale.io/en/services/28',
      imageDetectionLink: 'https://developers.gamescale.io/en/services/27',
    },
  },
  safeSecureEnvironment: {
    name: 'Safe & Secure Environment',
    title1: 'Enhanced payment security and ',
    title2: 'attack traffic blocking for stable service',
    title3: '',
    description:
      'Sophisticated detection and response strategies based on analysis data identify and counteract irregular activities and disguised attack traffic in payment processes. By ensuring the security of in-game transactions and protecting against network threats, this approach enables more stable game service.',
    features: {
      paymentAnomalyDetection: 'Payment Anomaly Detection',
      networkBlockingSystem: 'Network Blocking',
      paymentAnomalyDetectionLink: 'https://developers.gamescale.io/en/services/26',
      networkBlockingSystemLink: 'https://developers.gamescale.io/en/services/31',
    },
  },
  restrictionManagement: {
    name: 'Restriction Management',
    title1: 'Various sanctions enable appropriate ',
    title2: 'responses to different situations',
    description:
      'Efficiently addresses detected misconduct by offering both automatic real-time sanctions and manual interventions. Additionally, it allows for the control of suspicious accounts by setting appropriate in-game behavior limits.',
    features: {
      executionRestrictionSystem: 'Game Access Restriction',
      realtimeBehavioralRestrictionSystem: 'Real-time Behavioral Restriction',
      realtimeWeightControlSystem: 'Real-time Weight Control',
      executionRestrictionSystemLink: 'https://developers.gamescale.io/en/services/24',
      realtimeBehavioralRestrictionSystemLink: 'https://developers.gamescale.io/en/services/29',
      realtimeWeightControlSystemLink: 'https://developers.gamescale.io/en/services/30',
    },
  },
}

const operation = {
  seo: {
    title: 'GameScale | Operation Solution',
    description:
      'Operation Solution that supports various tools and systems for more stable management of live games',
    ogTitle: 'Operation Solution',
    ogDescription:
      'Operation Solution that supports various tools and systems for more stable management of live games',
  },
  banner: {
    title: 'Operation Solution',
    subtitle: 'that supports various tools and systems for more stable management of live games',
    description:
      'It reduces resources necessary for addressing user inconveniences and smooth game operation, maximizing work efficiency. It enhances operational quality and, as a result, increases user satisfaction.',
  },
  operationTools: {
    name: 'Operation Tools',
    title1: 'Operational support tools ',
    title2: 'that enable efficient user response',
    description:
      'Systematically combines functions needed for receiving and processing user inquiries maximize the speed and efficiency of customer support services, solving user problems more professionally.',
    features: {
      gameOperationTool: 'Game Operation Tool',
      customerCenter: 'Customer Center',
      autoCallingSystem: 'Auto Calling System',
      dataExtractionTool: 'Data Extraction',
      notification: 'Notifications',
      discordBot: 'Discord Bot',
      discordBotLink: 'https://developers.gamescale.io/en/services/59',
      dataExtractionToolLink: 'https://developers.gamescale.io/en/services/16',
      gameOperationToolLink: 'https://developers.gamescale.io/en/services/46',
      customerCenterLink: 'https://developers.gamescale.io/en/services/45',
      autoCallingSystemLink: 'https://developers.gamescale.io/en/services/44',
      notificationLink: 'https://developers.gamescale.io/en/services/50',
    },
  },
  restriction: {
    name: 'Restriction',
    title1: 'Real-time detection systems for ',
    title2: 'limiting violations of operational policies',
    description:
      'Monitors to detect disruptive behaviors such as profanity, malicious macros, and gold-farmers in real-time, along with associated sanction features, significantly reduce resources needed for related tasks.',
    features: {
      cheatDetection: 'Cheat Detection',
      gameAnomalyDetection: 'Game Anomaly Detection',
      textDetection: 'Text Detection',
      imageDetection: 'Image Detection',
      executionRestrictionSystem: 'Game Access Restriction',
      gameAnomalyDetectionLink: 'https://developers.gamescale.io/en/services/32',
      cheatDetectionLink: 'https://developers.gamescale.io/en/services/23',
      textDetectionLink: 'https://developers.gamescale.io/en/services/28',
      imageDetectionLink: 'https://developers.gamescale.io/en/services/27',
      executionRestrictionSystemLink: 'https://developers.gamescale.io/en/services/24',
    },
  },
  retention: {
    name: 'Retention',
    title1: 'Simple tools for ',
    title2: 'event management and production',
    description:
      'Supports coupon issuance and management, along with capabilities for creating event-related images, enabling the smooth and frequent execution of events, an effective means of acquiring users.',
    features: {
      coupons: 'Coupons',
      adCreator: 'AD Creator',
      couponsLink: 'https://developers.gamescale.io/en/services/52',
      adCreatorLink: 'https://developers.gamescale.io/en/services/47',
    },
  },
  compliance: {
    name: 'Compliance',
    title1: 'Detailed management systems ',
    title2: 'designed for legal compliance',
    description:
      'Manages fully complying with various legal requirements considered in game operation, such as data protection laws, youth protection laws, and regulations on random item offerings.',
    features: {
      personalInformationCollectionSystem: 'Personal Information Collection',
      personalInformationManagementSystem: 'Personal Information Management',
      protectionOfMinors: 'Protection of Minors',
      disclosureOfLootBoxOdds: 'Disclosure of Loot Box Odds',
      personalInformationCollectionSystemLink: 'https://developers.gamescale.io/en/services/37',
      personalInformationManagementSystemLink: 'https://developers.gamescale.io/en/services/55',
      protectionOfMinorsLink: 'https://developers.gamescale.io/en/services/62',
      disclosureOfLootBoxOddsLink: 'https://developers.gamescale.io/en/services/61',
    },
  },
}

const creator = {
  seo: {
    title: 'GameScale | Creators Solution',
    description: 'Creator solution designed to foster mutual growth between games and creators',
    ogTitle: 'Creators Solution',
    ogDescription: 'Creator solution designed to foster mutual growth between games and creators',
  },
  banner: {
    title: 'Creator solution',
    subtitle: 'designed to foster mutual growth\nbetween games and creators',
    description: `It encourages creator recruitment and quality game content creation through a program linked with rewards that can be converted into actual earnings. This provides enjoyment and benefits to both users and creators, creating a virtuous cycle that enhances the game's recognition and revenue.`,
  },
  sponsorship: {
    name: 'Sponsorship',
    title1: 'A platform where creators and users ',
    title2: 'grow together through sponsorship',
    description:
      'When creators in partnership with the game complete missions, sponsoring users receive rewards. When these users make in-game purchases, a portion of the payment is credited as points to the creators, who can then convert points into cash.',
    features: {
      creators: 'CREATORS',
      withdrawal: 'Withdrawal',
      creatorsLink: 'https://developers.gamescale.io/en/services/49',
      withdrawalLink: 'https://developers.gamescale.io/en/services/10',
    },
  },
  insights: {
    name: 'Insights',
    title1: 'Providing marketing insights ',
    title2: 'through analysis of creator activities',
    description:
      'We monitor trends in video content and analyze metrics such as market share and view counts. This enables more effective formulation of creator marketing strategies.',
    features: {
      trendi: 'TRENDi',
      trendiLink: 'https://developers.gamescale.io/en/services/21',
    },
  },
}

const matchmaking = {
  seo: {
    title: 'GameScale | Matchmaking Solution',
    description:
      'Matchmaking Solution delivers the best play experience by ensuring optimal game matchmaking',
    ogTitle: 'Matchmaking Solution',
    ogDescription:
      'Matchmaking Solution delivers the best play experience by ensuring optimal game matchmaking',
  },
  banner: {
    title: 'Matchmaking Solution',
    subtitle: 'delivers the best play experience by ensuring optimal game matchmaking',
    description: `Advanced matchmaking logic based on live game know-how and vast user data recommends the optimal opponents and play environment for users, enhancing the gaming experience.`,
  },
  matchmaking: {
    name: 'Matchmaking',
    title1: 'For every moment that requires matchmaking, ',
    title2: 'a matchmaker that can be easily integrated',
    description:
      'With advanced matching logic considering game and user traits, it recommends the ideal opponent and environment. It also offers real-time setting adjustments and monitoring. Usable in PVP to PVE modes, it supports easy API product integration.',
    features: {
      matchMob: 'MatchMob',
      matchMobLink: 'https://developers.gamescale.io/en/services/39',
    },
  },
  optimization: {
    name: 'Optimization',
    title1: 'More enjoyable game through ',
    title2: 'matchmaking ',
    title3: 'that considers for skill and preferences',
    description:
      'The matchmaking provides a high-quality system based on user data at request. Utilizing diverse information like cheating records, manners score, membership, and payment details, it enables customized game feature creation and optimal matchmaking designs by integrating multiple functions.',
    features: {
      userProfile: 'USER PROFILE',
      cheatDetection: 'Cheat Detection',
      userProfileLink: 'https://developers.gamescale.io/en/services/15',
      cheatDetectionLink: 'https://developers.gamescale.io/en/services/23',
    },
  },
}

const gamescaleAI = {
  seo: {
    title: 'GameScale | GameScale AI Solution',
    description:
      'GameScale AI Solution that employs advanced aritificial intelligence to infinitely expand gaming possibilities',
    ogTitle: 'GameScale AI Solution',
    ogDescription:
      'GameScale AI Solution that employs advanced aritificial intelligence to infinitely expand gaming possibilities',
  },
  banner: {
    title: 'GameScale AI Solution',
    subtitle:
      'that employs advanced aritificial intelligence to infinitely expand gaming possibilities',
    description: `AI consistently processes diverse tasks in real-time, enhancing efficiency and solving complex challenges. It also broadens technological possibilities, shaping new future for gaming industry.`,
  },
  security: {
    name: 'Security',
    title1: 'AI-powered real-time issue detection ',
    title2: 'for a fair and safe gameplay environment',
    description:
      'AI detects activities violating game rules and identifies elements in real time that can hinder user experience, enabling appropriate measures to be taken promptly.',
    features: {
      gameAnomalyDetection: 'Game Anomaly Detection',
      cheatDetection: 'Cheat Detection',
      visionHackDetection: 'Vision Hack Detection',
      gameAnomalyDetectionLink: 'https://developers.gamescale.io/en/services/32',
      cheatDetectionLink: 'https://developers.gamescale.io/en/services/23',
      visionHackDetectionLink: 'https://developers.gamescale.io/en/services/25',
    },
  },
  moderation: {
    name: 'Moderation',
    title1: 'Cleaner and healthier community ',
    title2: 'through AI analysis and chatbot integration',
    description:
      'The system creates a more pleasant community experience by detecting and removing behavior like profanity, obscenity, conflict-promoting language, and flaming in real-time in in-game chat and other game communities.',
    features: {
      discordBot: 'Discord Bot',
      textDetection: 'Text Detection',
      imageDetection: 'Image Detection',
      discordBotLink: 'https://developers.gamescale.io/en/services/59',
      textDetectionLink: 'https://developers.gamescale.io/en/services/28',
      imageDetectionLink: 'https://developers.gamescale.io/en/services/27',
    },
  },
  enhancement: {
    name: 'Enhancement',
    title1: 'Enhanced game experience through AI, ',
    title2: 'from customized matches to in-game product ',
    title3: 'and content',
    description:
      'AI analyzes user behavior and preferences to recommend the best matches, in-game products, and Socials to increase user enjoyment and maximize engagement. It also delivers personalized content from gaming data, enhancing attachment and reducing attrition.',
    features: {
      matchMob: 'MatchMob',
      productRecommendation: 'Product Recommendation',
      socialRecommendation: 'Social Recommendation',
      retentionKeeper: 'Retention Keeper',
      productRecommendationLink: 'https://developers.gamescale.io/en/services/40',
      matchMobLink: 'https://developers.gamescale.io/en/services/39',
      socialRecommendationLink: 'https://developers.gamescale.io/en/services/41',
      retentionKeeperLink: 'https://developers.gamescale.io/en/services/53',
    },
  },
  analysis: {
    name: 'Analysis',
    title: 'AI-based analysis tools for easy and convenient monitoring of game status',
    description:
      'By providing a content monitoring dashboard with AI-based video content and sentiment analysis capabilities, we make it easy to review content trends by game. AI chatbots enable intuitive data extraction and facilitate game churn analysis, enhancing analytical and operational efficiency.',
    features: {
      trendi: 'TRENDi',
      dataExtractionTool: 'Data Extraction',
      userprofile: 'USER PROFILE',
      dataExtractionToolLink: 'https://developers.gamescale.io/en/services/16',
      trendiLink: 'https://developers.gamescale.io/en/services/21',
      userProfileLink: 'https://developers.gamescale.io/en/services/15',
    },
  },
}

const marketing = {
  seo: {
    title: 'GameScale | Marketing Solution',
    description:
      'Marketing solution that utilizes customized strategies to attract users and prevent user attrition',
    ogTitle: 'Marketing Solution',
    ogDescription:
      'Marketing solution that utilizes customized strategies to attract users and prevent user attrition',
  },
  banner: {
    title: 'Marketing solution',
    subtitle: 'that utilizes customized strategies to attract users and prevent user attrition',
    description: `It utilizes advanced data and AI technologies to identify targets. By supporting all aspects of marketing from advertising operations and event management to market analysis, it facilitates effective acquisition and retention of new users.`,
  },
  targetAnalysis: {
    name: 'Target Analysis',
    title1: 'Data-driven targeting ',
    title2: 'for efficient ads operations',
    description:
      'We meticulously select marketing targets by analyzing large-scale in-game data aggregated on a user basis. With just a few clicks, you can easily extract a customized target audience that meets your specific criteria.',
    features: {
      userProfile: 'USER PROFILE',
      dataExtractionTool: 'Data Extraction',
      dataExtractionToolLink: 'https://developers.gamescale.io/en/services/16',
      userProfileLink: 'https://developers.gamescale.io/en/services/15',
    },
  },
  adCreation: {
    name: 'Ad Creation',
    title1: 'Quick and easy ',
    title2: 'customized banner creation',
    description:
      'We provide a range of design templates and assets for various concepts, allowing even non-experts to easily create high-quality, personalized banners. Utilizing game-specific resources, banners can also be generated automatically.',
    features: {
      adCreator: 'AD Creator',
      adCreatorLink: 'https://developers.gamescale.io/en/services/47',
    },
  },
  marketingMedia: {
    name: 'Marketing Media',
    title: 'Various marketing channels for optimal utilization',
    description:
      'We offer a wide range of marketing channels that allow message delivery to each user, not only through internal media like websites, launchers, and apps but also via external media such as PC cafes, notifications, MMS, LMS, and email.',
    features: {
      nexonAdvertisement: 'NEXON Adverisement',
      customPortal: 'Custom Portal',
      pcCafeAffiliation: 'PC Cafe Affiliation',
      notifications: 'Notifications',
      customPortalLink: 'https://developers.gamescale.io/en/services/42',
      nexonAdvertisementLink: 'https://developers.gamescale.io/en/services/54',
      notificationsLink: 'https://developers.gamescale.io/en/services/50',
      pcCafeAffiliationLink: 'https://developers.gamescale.io/en/services/48',
    },
  },
  retention: {
    name: 'Retention',
    title: "Sustaining player's interest with tailored content",
    description:
      "We identify user groups at high risk of churn and provide personalized content, such as messages and guides, based on their gameplay data. Content that precisely fits the context of the user's play can strengthen attachment to the game and prevent churn.",
    features: {
      retentionKeeper: 'Retention Keeper',
      guideRecommendation: 'Guide Recommendation',
      guideRecommendationLink: 'https://developers.gamescale.io/en/services/43',
      retentionKeeperLink: 'https://developers.gamescale.io/en/services/53',
    },
  },
  creatorMarketing: {
    name: 'Creator Marketing',
    title: 'Creator content as a trendy and attractive user acquisition model',
    description:
      'We support creators producing high-quality game content through a reward-linked sponsorship system, continuously motivating them to produce content. The content created by these creators naturally attracts new users to the game and enhances the retention of existing users.',
    features: {
      creators: 'CREATORS',
      creatorsLink: 'https://developers.gamescale.io/en/services/49',
    },
  },
  promotionManagement: {
    name: 'Promotion Management',
    title1: 'Safe and convenient ',
    title2: 'event organization and management',
    description:
      'We securely collect personal information from domestic and international users in compliance with legal regulations to distribute prizes. Additionally, our robust and flexible coupon management system allows for easy issuance of event coupons.',
    features: {
      personalInformationCollectionSystem: 'Personal Information Collection',
      coupons: 'Coupons',
      couponsLink: 'https://developers.gamescale.io/en/services/52',
      personalInformationCollectionSystemLink: 'https://developers.gamescale.io/en/services/37',
    },
  },
  marketInsights: {
    name: 'Market Insights',
    title: 'Providing market trends and insights via global game market monitoring',
    description:
      'We monitor the status of video content within the community and provide a visualized dashboard. This allows for easy identification of content trends by game. Additionally, by analyzing real-time data from PC cafes nationwide, we grasp the flow of the domestic PC game market and identify user needs through various UX research tools.',
    features: {
      trendi: 'TRENDi',
      pcCafeAffiliation: 'PC Cafe Affiliation',
      uxResearchPlatform: 'UX Research Platform',
      trendiLink: 'https://developers.gamescale.io/en/services/21',
      pcCafeAffiliationLink: 'https://developers.gamescale.io/en/services/48',
      uxResearchPlatformLink: 'https://developers.gamescale.io/en/services/19',
    },
  },
}

const qa = {
  seo: {
    title: 'GameScale | QA Solution',
    description:
      'QA solution that manages optimal gameplay environment by conducting various tests',
    ogTitle: 'QA Solution',
    ogDescription:
      'QA solution that manages optimal gameplay environment by conducting various tests',
  },
  banner: {
    title: 'QA solution',
    subtitle: 'that manages optimal gameplay environment by conducting various tests',
    description: `It provides various tools to enhance the efficiency of diverse tests conducted by QA experts, contributing to the creation of an optimal gaming environment and improving user experience.`,
  },
  advancedTools: {
    name: 'Advanced Tools',
    title: "Various tools and services supporting QA experts' testing",
    description:
      'We assist QA experts in performing their tasks efficiently through test case management and automated testing tools. Additionally, we provide technologies such as app performance measurement, continuous test screen recording, and client performance data aggregation to ensure thorough quality checks of the game.',
    features: {
      qaAutomation: 'QA Automation and Optimization',
      qaSupportTools: 'QA Support Tools',
      clientPerformanceMeasurement: 'Client Performance Measurement',
      crashReport: 'Crash Report',
      qaAutomationLink: 'https://developers.gamescale.io/en/services/64',
      qaSupportToolsLink: 'https://developers.gamescale.io/en/services/63',
      clientPerformanceMeasurementLink: 'https://developers.gamescale.io/en/services/65',
      crashReportLink: 'https://developers.gamescale.io/en/services/66',
    },
  },
  qualityVerification: {
    name: 'Quality Verification',
    title1: 'Support for various tests ',
    title2: 'to ensure the game functions correctly',
    description:
      'We conduct performance tests on PCs and mobile devices, verify compatibility across different hardware combinations and software environments, and perform stress and network load tests. Additionally, we provide reports on the test results.',
    featureDetails: {
      hardwareCompatibilityVerification: {
        title: 'Hardware Compatibility Verification',
        hardwareCompatibilityTest: {
          title: 'Hardware Compatibility Test',
          content1: 'Verifies compatibility between components like CPU, GPU, and Memory.',
          content2: 'Confirms client execution suitability based on device specification grading.',
        },
        performanceTest: {
          title: 'Performance Test',
          content: 'Measures fps upon execution and checking system resource occupancy.',
        },
      },
      softwareCompatibilityVerification: {
        title: 'Software Compatibility Verification',
        creationOfArtificialEntryBarriers: {
          title: 'Creation of Artificial Entry Barriers',
          content: 'Verifies normal operation in various environments.',
        },
        verificationOfExecutableOSVersions: {
          title: 'Verification of executable OS versions',
          content: "Verifies the game's functionality across various operating system versions.",
        },
        antivirusCompatibilityVerification: {
          title: 'Antivirus Compatibility Verification',
          content:
            'Tests compatibility and detects conflicts between the game and antivirus software.',
        },
        worldwideAntiVirusTest: {
          title: 'Worldwide Anti-Virus Test',
          content:
            'Verifies that the game client and installation files are not detected as threats by various antivirus software.',
        },
      },
    },
  },
}

const analytics = {
  seo: {
    title: 'GameScale | Analytics solution',
    description:
      'Analytics solution that provides strategies to improve game performance through data-driven situational analysis',
    ogTitle: 'Analytics solution',
    ogDescription:
      'Analytics solution that provides strategies to improve game performance through data-driven situational analysis',
  },
  banner: {
    title: 'Analytics solution',
    subtitle:
      'that provides strategies to improve game performance through data-driven situational analysis',
    description: `Systematic analysis across genres like MMORPG, Action, FPS, Racing and Sports includes data extraction, user behavior analysis, and situation monitoring. Results clarify the current situation and guide strategies to reduce churn and increase revenue.`,
  },
  data: {
    name: 'Data',
    title: 'Understanding effective gaming experiences through diverse foundational data',
    description:
      'Specialized data collection and extraction tools are provided to enable in-depth analysis of game experiences, from gameplay information to actual user feedback. Data is presented in a format that is easy to analyze and access, making it easier to understand user requirements.',
    features: {
      dataExtractionTool: 'Data Extraction',
      userProfile: 'USER PROFILE',
      monolake: 'Monolake',
      uxResearchPlatform: 'UX Research Platform',
      monolakeLink: 'https://developers.gamescale.io/en/services/17',
      dataExtractionToolLink: 'https://developers.gamescale.io/en/services/16',
      userProfileLink: 'https://developers.gamescale.io/en/services/15',
      uxResearchPlatformLink: 'https://developers.gamescale.io/en/services/19',
    },
  },
  analysis: {
    name: 'Analysis',
    title: 'Analyzing user test and behavior logs to develop strategies for game growth',
    description:
      'We analyze the critical success factors of potential users and target markets by gathering user pain points directly through user testing and validating various hypotheses based on logs. Based on this, we establish a direction to meet user expectations and derive strategies to improve the game experience.',
    features: {
      dataAnalysis: 'Data Analysis',
      uxAnalysis: 'UX Analysis',
      uxAnalysisLink: 'https://developers.gamescale.io/en/services/20',
      dataAnalysisLink: 'https://developers.gamescale.io/en/services/18',
    },
  },
  report: {
    name: 'Report',
    title: 'Tracking growth and measuring success with reports that consolidate game metrics',
    description:
      'Reports on key metrics for game development and operations enable quantitative analysis of game performance and strategic planning. Monitoring KPI achievement, game content usage, and anomalies provides an effective understanding of the current state of the game.',
    features: {
      nxLogReport: 'NXLog Report',
      nxLogReportLink: 'https://developers.gamescale.io/en/services/14',
    },
  },
  trends: {
    name: 'Trends',
    title: 'Analyzing the latest games and market trends based on YouTube and PC cafe data',
    description:
      'You can monitor the content pool using game IPs and visualize the latest trends through a dashboard and analyze the flow of the games market with real-time data from PC cafes across the country.',
    features: {
      trendi: 'TRENDi',
      pcCafeAffiliation: 'PC Cafe Affiliation',
      trendiLink: 'https://developers.gamescale.io/en/services/21',
      pcCafeAffiliationLink: 'https://developers.gamescale.io/en/services/48',
    },
  },
}

const userCare = {
  seo: {
    title: 'GameScale | User Care Solution',
    description:
      'User Care Solution that boosts game participation and trust with ongoing communication and careful management',
    ogTitle: 'User Care Solution',
    ogDescription:
      'User Care Solution that boosts game participation and trust with ongoing communication and careful management',
  },
  banner: {
    title: 'User Care Solution',
    subtitle:
      'that boosts game participation and trust with ongoing communication and careful management',
    description: `It protects users' gaming experiences and systematically offers various benefits. This enhances the fun and trust in the game by creating a smooth communication environment with users and optimizing user matching, contributing to improved retention.`,
  },
  userFeedback: {
    name: 'User Feedback',
    title1: 'A variety of channels through ',
    title2: 'which users can express their opinions',
    description:
      'It provides a variety of channels, such as communities, customer centers, and user tests, enabling users to voice their opinions in diverse ways. Through meticulous and attentive management that keenly incorporates user feedback, the gaming experience for users is significantly enriched.',
    features: {
      uxResearchPlatform: 'UX Research Platform',
      officialCommunity: 'Official Community',
      customerCenter: 'Customer Center',
      customerCenterLink: 'https://developers.gamescale.io/en/services/45',
      officialCommunityLink: 'https://developers.gamescale.io/en/services/60',
      uxResearchPlatformLink: 'https://developers.gamescale.io/en/services/19',
    },
  },
  userTrust: {
    name: 'User Trust',
    title1: 'Enhancing user trust in games through ',
    title2: 'transparent operations and rigorous ',
    title3: 'security',
    description:
      "Features are provided to comply with laws, including the transparent disclosure of gacha item probabilities and the management of youth usage times. Additionally, because enhanced security measures ensure that users' accounts are securely protected, it's possible to increase users' trust in the game.",
    features: {
      disclosureOfLootBoxOdds: 'Disclosure of Loot Box Odds',
      protectionOfMinors: 'Protection of Minors',
      twoFactorAuthentication: 'Two-Factor Authentication',
      accountTrustRating: 'Account Trust Rating',
      accountTrustRatingLink: 'https://developers.gamescale.io/en/services/35',
      twoFactorAuthenticationLink: 'https://developers.gamescale.io/en/services/38',
      protectionOfMinorsLink: 'https://developers.gamescale.io/en/services/62',
      disclosureOfLootBoxOddsLink: 'https://developers.gamescale.io/en/services/61',
    },
  },
  connection: {
    name: 'Connection',
    title1: 'Strengthening the joy of togetherness ',
    title2: 'through delicate user connections',
    description:
      'By matching the optimal opponents and teammates based on user information, the enjoyment of the game is maximized. Additionally, facilitating communication among users through a messenger system and recommended chat groups enhances interaction.',
    features: {
      matchMob: 'MatchMob',
      socialRecommendation: 'Social Recommendation',
      messenger: 'Messenger',
      matchMobLink: 'https://developers.gamescale.io/en/services/39',
      socialRecommendationLink: 'https://developers.gamescale.io/en/services/41',
      messengerLink: 'https://developers.gamescale.io/en/services/58',
    },
  },
  participationBoosting: {
    name: 'Participation Boosting',
    title1: 'Maximizing user engagement ',
    title2: 'with various benefits and events',
    description:
      'Through partnerships with nationwide affiliated PC cafes offering premium benefits and a flexible coupon system, along with utilizing Discord bot services and creator support programs, a variety of events can be conducted. The benefits provided through these initiatives serve to enhance user engagement and satisfaction.',
    features: {
      pcCafeAffiliation: 'PC Cafe Affiliation',
      coupons: 'Coupons',
      discordBot: 'Discord Bot',
      creators: 'CREATORS',
      discordBotLink: 'https://developers.gamescale.io/en/services/59',
      creatorsLink: 'https://developers.gamescale.io/en/services/49',
      couponsLink: 'https://developers.gamescale.io/en/services/52',
      pcCafeAffiliationLink: 'https://developers.gamescale.io/en/services/48',
    },
  },
}

const business = {
  seo: {
    title: 'GameScale | Business Solution',
    description: 'Business Solution that strategically builds a pipeline for game success',
    ogTitle: 'Business Solution',
    ogDescription: 'Business Solution that strategically builds a pipeline for game success',
  },
  banner: {
    title: 'Business Solution',
    subtitle: 'that strategically builds\na pipeline for game success',
    description: `Based on a deep understanding of game users, it encourages continuous play, thereby generating economic value. This establishes a solid foundation necessary for the successful launch and sustainable operation of the game.`,
  },
  insights: {
    name: 'Insights',
    title1: 'Analytical tools that process ',
    title2: 'in-game data into business insights',
    description:
      'We provide necessary indicators from vast in-game data in the form of dashboards and reports. Detailed analysis of user behavior and preferences based on this data serves as a strong foundation for business strategy.',
    features: {
      nxLogReport: 'NXLog Report',
      userProfile: 'USER PROFILE',
      dataExtractionTool: 'Data Extraction',
      dataExtractionToolLink: 'https://developers.gamescale.io/en/services/16',
      userProfileLink: 'https://developers.gamescale.io/en/services/15',
      nxLogReportLink: 'https://developers.gamescale.io/en/services/14',
    },
  },
  retentionStrategy: {
    name: 'Retention Strategy',
    title1: 'Retention enhancement strategies ',
    title2: 'for sustainable business growth',
    description:
      'From targeted advertising campaigns for churned users to content marketing linked with creator sponsorships, we utilize strategies suited to the situation and objective to efficiently retain users.',
    features: {
      retentionKeeper: 'Retention Keeper',
      creators: 'CREATORS',
      retentionKeeperLink: 'https://developers.gamescale.io/en/services/53',
      creatorsLink: 'https://developers.gamescale.io/en/services/49',
    },
  },
  commerce: {
    name: 'Commerce',
    title1: 'Seamless in-game economic system ',
    title2: 'from purchase motivation to payment',
    description:
      'We recommend items for sale in the in-game store tailored to user needs to stimulate the desire to purchase. Additionally, we provide convenient payment and cash recharge systems to facilitate actual purchases.',
    features: {
      productRecommendation: 'Product Recommendation',
      store: 'Store',
      payments: 'Payments',
      productRecommendationLink: 'https://developers.gamescale.io/en/services/40',
      storeLink: 'https://developers.gamescale.io/en/services/12',
      paymentsLink: 'https://developers.gamescale.io/en/services/13',
    },
  },
  pcCafe: {
    name: 'PC-Cafe',
    title1: 'New revenue model created through ',
    title2: 'mutual growth with PC cafes',
    description:
      'We sell exclusive rights to PC café owners to offer premium benefits only available in their venues, enhancing visitor frequency and creating a revenue model that satisfies all parties involved. Additionally, we provide management and marketing solutions leveraging PC café infrastructure.',
    features: {
      pcCafeAffiliation: 'PC Cafe Affiliation',
      pcCafeAffiliationLink: 'https://developers.gamescale.io/en/services/48',
    },
  },
}

const community = {
  seo: {
    title: 'GameScale | Community solution',
    description:
      'Community solution that creates active communication among users and between users and the game operators',
    ogTitle: 'Community solution',
    ogDescription:
      'Community solution that creates active communication among users and between users and the game operators',
  },
  banner: {
    title: 'Community solution',
    subtitle:
      'that creates active communication among users and between users and the game operators',
    description: `Encourages user interaction through game communities and social features. Promotes a healthy communication environment, encourages close interactions with users, gathering feedback, and gaining insights for game improvement and development.`,
  },
  engagement: {
    name: 'Engagement',
    title1: 'Community environment creation tool ',
    title2: 'that is easy and simple to use',
    description:
      'It offers customizable official communities tailored to game features and service areas. Additionally, it provides various forms of chat including text, voice, and video, as well as a messenger service across multiple platforms.',
    features: {
      officialCommunity: 'Official Community',
      chat: 'Chat',
      messenger: 'Messenger',
      messengerLink: 'https://developers.gamescale.io/en/services/58',
      officialCommunityLink: 'https://developers.gamescale.io/en/services/60',
      chatLink: 'https://developers.gamescale.io/en/services/56',
    },
  },
  encouragement: {
    name: 'Encouragement',
    title1: 'Activate communication channels with ',
    title2: 'tailored social recommendations for users',
    description:
      'It recommends the best guilds, guild members, party members, friends, based on user play experience and demographic information. Also, by recommending relevant chat groups to users who meet certain criteria during play, it creates an environment where multiple users can communicate about shared experiences.',
    features: {
      socialRecommendation: 'Social Recommendation',
      socialRecommendationLink: 'https://developers.gamescale.io/en/services/41',
    },
  },
  management: {
    name: 'Management',
    title1: 'Optimized community experience ',
    title2: 'through efficient management',
    description:
      'It utilizes a Discord bot with features like automated messaging, news alerts, FAQs, and coupon distribution for game community management. It also uses AI to detect and block harmful content in chats and uploaded images, ensuring a healthy communication environment.',
    features: {
      discordBot: 'Discord Bot',
      textDetection: 'Text Detection',
      imageDetection: 'Image Detection',
      discordBotLink: 'https://developers.gamescale.io/en/services/59',
      textDetectionLink: 'https://developers.gamescale.io/en/services/28',
      imageDetectionLink: 'https://developers.gamescale.io/en/services/27',
    },
  },
}

const developmentSupport = {
  seo: {
    title: 'GameScale | Development support solution',
    description:
      'Development support solution that facilitates easy implementation of various game features',
    ogTitle: 'Development support solution',
    ogDescription:
      'Development support solution that facilitates easy implementation of various game features',
  },
  banner: {
    title: 'Development Support Solution',
    subtitle: 'that facilitates easy implementation of various game features',
    description: `It offers a wide range of features necessary for game development and release, from complex essentials like authentication and billing to easy-to-use support features. It also reduces the burden of implementing each feature individually, allowing developers to focus on improving game quality and market competitiveness.`,
  },
  authentication: {
    name: 'Authentication',
    title1: 'Comprehensive and solid ',
    title2: 'authentication and account system',
    description:
      'Provides detailed support for the account systems and authentication processes required for game and service usage, from member management to login and account validation. Also provides a global account system for internationally released games and supports cross-platform development.',
    features: {
      nexonLogin: 'NEXON Login',
      nexonMembers: 'NEXON Members',
      integratedAccountLinking: 'Integrated Account Linking',
      memberChanneling: 'Member Channeling',
      nexonLoginLink: 'https://developers.gamescale.io/en/services/7',
      nexonMembersLink: 'https://developers.gamescale.io/en/services/6',
      integratedAccountLinkingLink: 'https://developers.gamescale.io/en/services/9',
      memberChannelingLink: 'https://developers.gamescale.io/en/services/8',
    },
  },
  commerce: {
    name: 'Commerce',
    title1: 'Flexible and secure ',
    title2: 'payment and store system',
    description:
      'You can easily integrate a payment system, including advanced verification logic, into your game platform. It also supports item sales and management capabilities across multiple interfaces, including in-game, web, and launcher.',
    features: {
      payments: 'Payments',
      store: 'Store',
      currency: 'Currency',
      withdrawal: 'Withdrawal',
      withdrawalLink: 'https://developers.gamescale.io/en/services/10',
      storeLink: 'https://developers.gamescale.io/en/services/12',
      paymentsLink: 'https://developers.gamescale.io/en/services/13',
      currencyLink: 'https://developers.gamescale.io/en/services/11',
    },
  },
  clientAndServerUtilites: {
    name: 'Client & Server Utilities',
    title1: 'Streamlined and systematic ',
    title2: 'game execution and server support',
    description:
      'Provides libraries for easy game server creation and client integration. Also allows easy management of the entire process from game build management to download, installation, updates and execution.',
    features: {
      serverBuildingLibrary: 'Server Building Library',
      launcher: 'Launcher',
      deployment: 'Deployment',
      crashReport: 'Crash Report',
      clientPerformanceMeasurement: 'Client Performance Measurement',
      serverBuildingLibraryLink: 'https://developers.gamescale.io/en/services/69',
      clientPerformanceMeasurementLink: 'https://developers.gamescale.io/en/services/65',
      crashReportLink: 'https://developers.gamescale.io/en/services/66',
      launcherLink: 'https://developers.gamescale.io/en/services/4',
      deploymentLink: 'https://developers.gamescale.io/en/services/3',
    },
  },
  developmentModule: {
    name: 'Development Module',
    title1: 'Development support module ',
    title2: 'efficient for implementing in-game content',
    description:
      'Allows web pages to be displayed within the client without impacting system resources. Delivered in a modular form, it can be used effectively across different in-game content, tailored to the characteristics of the game.',
    features: {
      webViewSupport: 'Web View Support',
      webViewSupportLink: 'https://developers.gamescale.io/en/services/68',
    },
  },
}

export const solutionDetail = {
  personalizedRecommendation,
  riskManagement,
  operation,
  creator,
  matchmaking,
  gamescaleAI,
  marketing,
  qa,
  analytics,
  userCare,
  business,
  community,
  developmentSupport,
}
